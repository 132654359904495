import React from 'react';
import NavigationHeader from './Partials/HeaderComponent';
import NavigationFooter from './Partials/FooterComponent';
// import ScrollToTop from "react-scroll-to-top";
const MainLayout = ({ children }) => (
  <>
  {/* <ScrollToTop smooth color="#295c53" /> */}
  
    <NavigationHeader />
    {children}
    <NavigationFooter  />
  </>
);

export default MainLayout;
