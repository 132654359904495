import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./pages/Layout";
import Home from "./Screens/HomeScreen";
import News from "./Screens/NewsScreen";
import Cookies from "./Screens/CookiesScreen";
import NoPage from "./Screens/NoPageScreen";
import About from './Screens/AboutScreen';
import Privacy from './Screens/PrivacyScreen';
import TermsCondition from './Screens/Terms_ConditionScreen';
import MainLayout from './Layouts/MainLayout';
import ScrollToTop from './Components/ScrollToTop';
import Newsdetail from './Screens/NewsDetails';

export default function App() {
  return (
    <BrowserRouter>
   <ScrollToTop/>
      <Routes>
          {/* <Route index element={<Home />} /> */}
          <Route  path="/"  element={<MainLayout><Home /></MainLayout>}/>
          <Route path="/news" element={<MainLayout> <News /></MainLayout>} />
          <Route path="/cookies" element={<MainLayout><Cookies/></MainLayout>} />
          <Route path="/about" element={<MainLayout><About /></MainLayout>} />
          <Route path="/privacy" element={<MainLayout><Privacy /></MainLayout>} />
          <Route path="/terms-and-condition" element={<MainLayout><TermsCondition /></MainLayout>} />
          <Route path="/newsdetail"  element={<MainLayout><Newsdetail/></MainLayout>} />
          <Route path="*" element={<NoPage />} />
        {/* contacts/:id */}
      </Routes>
    </BrowserRouter>
  );
}

