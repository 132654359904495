import Modal from "../../CommonModal/Modal";
import { Link } from "react-router-dom";
import UserRegistration from "./UserRegistration";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const UserWelcome=({isModalOpenGet,setModalOpenGet}) =>{
  const [isOpenRegistration, setOpenRegistration] = useState(false);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .email("Invalid email address")
    .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
     // alert(JSON.stringify(values, null, 2));
      console.log(JSON.stringify(values, null, 2));
      setOpenRegistration(true);
    },
  });
  

  return (
    <>
      {isModalOpenGet && (
        <Modal onClose={() => setModalOpenGet(false)}>
          <form onSubmit={formik.handleSubmit}>
          <h2 data-v-9ac1a4e2="" id="email-title" className="md:text-4xl text-4xl font-[800] md:font-semibold font-roboto"> Welcome! </h2>
          <label htmlFor="email" className="font-[400] text-[14px] font-poppins">Enter Email*</label>
          <input  
            type="email" 
            id="email" 
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter Email"
            className="border border-gray-300 rounded-md py-2 px-2 w-full bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
          />
          {formik.touched.email && formik.errors.email ? (
              <p className="text-red-500">{formik.errors.email}</p>
            ) : null}
          <br/>
          <button id="email-submit" type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-[400] py-3 px-4 rounded-lg mt-8 w-full" data-v-9ac1a4e2="" 
            onClick={() =>{ setOpenRegistration(true); setModalOpenGet(false)}}
           > Continue </button>
          <div id="textB" className="bg-blue-500 hover:bg-blue-600 font-normal py-3 px-4 rounded-lg mt-8 w-full text-black mb-10 text-center" data-v-9ac1a4e2=""> Invisible Recaptcha by Google <span className="font-bold" data-v-9ac1a4e2="">
            <Link to="#" className="no-underline" data-v-9ac1a4e2="">Privacy Policy</Link></span> and <span class="font-bold" data-v-9ac1a4e2="">
            <Link to="#" className="no-underline" data-v-9ac1a4e2="">Terms of Use</Link></span></div>
          </form>
        </Modal>
      )}
      <UserRegistration isOpenRegistration={isOpenRegistration} setOpenRegistration={setOpenRegistration}/>
    </>
  );
}
export default UserWelcome;



