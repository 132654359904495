import React from "react";
import { useState } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const reviews = [
  {
    review_text:
      "This is a fantastic product! I recently started using a finance product and I must say, it has completely changed the way I manage my money.",
    user: {
      user_name: "John Doe",
    },
  },
  {
    review_text:
      "I recently started using a finance product and I must say, it has completely changed the way I manage my money. Before, I found myself constantly struggling to keep track of my expenses and stick to a budget.",
    user: {
      user_name: "Jane Smith",
    },
  },

  {
    review_text:
      "This is a fantastic product! I recently started using a finance product and I must say, it has completely changed the way I manage my money.",
    user: {
      user_name: "John Doe",
    },
  },

  {
    review_text:
      "I recently started using a finance product and I must say, it has completely changed the way I manage my money. Before, I found myself constantly struggling to keep track of my expenses and stick to a budget.",
    user: {
      user_name: "Jane Smith",
    },
  },

  {
    review_text:
      "This is a fantastic product! I recently started using a finance product and I must say, it has completely changed the way I manage my money.",
    user: {
      user_name: "John Doe",
    },
  },

  {
    review_text:
      "I recently started using a finance product and I must say, it has completely changed the way I manage my money. Before, I found myself constantly struggling to keep track of my expenses and stick to a budget.",

    user: {
      user_name: "Jane Smith",
    },
  },
  {
    review_text:
      "This is a fantastic product! I recently started using a finance product and I must say, it has completely changed the way I manage my money.",
    user: {
      user_name: "John Doe",
    },
  },
  {
    review_text:
      "I recently started using a finance product and I must say, it has completely changed the way I manage my money. Before, I found myself constantly struggling to keep track of my expenses and stick to a budget.",

    user: {
      user_name: "Jane Smith",
    },
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    if (currentIndex < reviews.length - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }
  };

  const prevSlide = () => {
    if (currentIndex >= 1) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
      );
    }
  };
  return (
    <div id="testimonial" className="testimonials">
      <b className="testimonials1">Testimonials</b>
      <div className="testi-block testimonials-block flex flex-col md:flex-row">
        <div className="quote">
          <div className="quote1 testimonial-carousel">
            <div className="texticon one-slide flex flex-row w-full">
              <div className="flex flex-col w-[100%] md:w-[4%]">
                <img
                  className="iconquote flex flex-col"
                  alt=""
                  src="images/iconquote.png"
                />
              </div>
              <div className="text12 testimonial flex flex flex-wrap w-[95%]">
                <div className="i-recently-started flex flex-row w-full flex-start items-start">
                  {reviews[currentIndex].review_text}
                </div>

                <div className="jenny-wilson flex flex-row w-full flex-start items-start">
                  {reviews[currentIndex].user.user_name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="all-rights-reserved">
            <span className="current-slide">
              {String(currentIndex + 1).padStart(2, "0")}
            </span>

            <span className="span2">/{reviews.length}</span>
          </div>

          <div className="btns controls-btn carousel-controls testimonial-carousel-controls">
            <div className="control previous slick-arrow  " onClick={nextSlide}>
              <img
                className="iconarrowleftround  opacity-100"
                alt="Previous"
                src="images/iconarrowrightround.svg"
              />
            </div>
            <div className="control next slick-arrow" onClick={prevSlide}>
              <img
                className="iconarrowrightround opacity-50"
                alt="Next"
                src="images/active-left-arrow.svg"
              />
            </div>
          </div>

          {/* <div className="btns controls-btn carousel-controls testimonial-carousel-controls">
            <div className="control previous slick-arrow" onClick={nextSlide}>
              <button className="Nextbtn">
                <img
                  className="iconarrowleftround"
                  alt="Previous"
                  src="images/iconarrowrightround.svg"
                />
              </button>
            </div>
            <div className="control next slick-arrow" onClick={prevSlide}>
              <button className="Nextbtn">
                <img
                  className="iconarrowrightround"
                  alt="Next"
                  src="images/active-left-arrow.svg"
                />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
