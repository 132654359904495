import React from "react";
import "../CommonModal/Modal.css"
const Modal=({ children, onClose })=> {
  return (
    <div id="overlay">
      <div id="modal">
        {children}
        <button id="closeButton" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
}
export default Modal;
