import "../assets/css/custom.css";

const About = () => {
    
    return (
    <>
        <p id="container" className="font-roboto text-center  text-[2rem] md:text-[3.375rem] font-[700] xl:w-[31%] mx-auto w-[70%] md:w-[60%]">Empower users to achieve
            more</p>
        <div id="section"className="container min-w-[95%] about-section mx-auto">
    
            <div id="items" className="flex flex-col items-center">
    
                <p className="subtitle-about-empower pt-8 lg:px-[11rem]">We can help you easily bring together all your financial
                    information and get a complete overview of the factors that impact your finances.
                </p>
    
                <div id="iten-center" className="flex justify-center items-center mt-8">
                    <img src="images/graphic-img.png" alt="graphic_img" className="sm:max-w-[100%] max-w-[100%] xl:max-w-[100%]"/>
                </div>
            </div>
            <p className="text-center font-roboto md:text-[2.75rem] font-[700] text-[1.5rem] md:pt-12 pt-10">About</p>
            <p className="text-center font-poppins md:text-[1rem] text-[0.875rem] font-[400] lg:px-[9.5rem] md:pt-0">As a child, my parents
                taught me to save money for a rainy day, but that was the extent of my financial education. Unfortunately, most
                of us struggle with managing our finances and lack the knowledge of smart savings and investments.</p>
        </div>
    
    
        <div id="about-section" className="container about-section mx-auto max-w-[100%] min-w-[85%]">
            <div className="grid grid-cols-1 lg:grid-cols-2 pt-10 gap-4">
                <div className="card radious-card w-[95%] h-[23.75rem] md:w-[85%] md:h-[32.5rem] lg:w-[75%] lg:h-[33.5rem] xl:w-[100%] xl:h-[32.5rem] mx-auto">
                    <div className="fade-card mx-auto relative md:top-[3.25rem] top-[2.125rem]"></div>
                    <div className="inner-card mx-auto md:w-[100%] md:h-[16.563rem] w-[100%] h-[10.688rem]">
                        <img src="images/score.png" className="img-progress-bar mx-auto md:pt-10 pt-8" alt="score"/>
                        <p className="font-poppins text-[0.875rem] md-text[1rem] font-[400] text-center lg:pt-20 pt-12 md:pt-16 lg:px-8">
                            With MySavvyWallet, you can now conveniently view all your income and expenses in one place. Analyze your spending patterns, track when bills are due, create and manage budgets. 
                        </p>
                    </div>
                </div>
    
                <div id="radious-card" className="card radious-card w-[95%] h-[23.75rem] md:w-[85%] md:h-[32.5rem] lg:w-[75%] lg:h-[33.5rem] xl:w-[100%] xl:h-[32.5rem] mx-auto">
                    <div id="fade-card" className="fade-card mx-auto relative md:top-[3.25rem] top-[2.125rem]"></div>
                    <div id="inner-card" className="inner-card mx-auto md:w-[100%] md:h-[16.563rem] w-[100%] h-[10.688rem]">
                        <img src="images/upcoming-bills.png" className="img-upcoming mx-auto md:pt-10 pt-4" alt="upcoming_bills"/>
                        <p className="font-poppins text-[0.875rem] md-text[1rem] font-[400] text-center lg:pt-[3.7rem] pt-12 lg:px-4">
                            See the list of your paid subscriptions, track your expenses each month, and make informed decisions to achieve financial independence.
                        </p>
                    </div>
                </div>
    
                <div id="card radious-card" className="card radious-card w-[95%] h-[23.75rem] md:w-[85%] md:h-[32.5rem] lg:w-[75%] lg:h-[33.5rem] xl:w-[100%] xl:h-[32.5rem] mx-auto p-4">
                    <div id="fade-card" className="fade-card mx-auto relative md:top-[3.25rem] top-[2.125rem]"></div>
    
                    <div id="innerCard" className="inner-card mx-auto md:w-[100%] md:h-[16.563rem] w-[100%] h-[10.688rem]">
                        <img src="images/group-126.png" className="img-security-lock mx-auto md:pt-5 pt-1" alt=""/>
                        <img src="images/frame-130.png" className=" mx-auto pt-5 md:max-w-[100%] max-w-[85%]" alt=""/>
                        <p className="font-poppins text-[0.875rem] md-text[1rem] font-[400] text-center md:pt-16 lg:pt-20 pt-[2rem]">
                            You can check your account information and make transactions from anywhere, at any time. We use encryption and 2-factor authorization to protect your personal and financial information.
                        </p>
                    </div>
                </div>
    
                <div id="radious" className="card radious-card w-[95%] h-[23.75rem] md:w-[85%] md:h-[32.5rem] lg:w-[75%] lg:h-[33.5rem] xl:w-[100%] xl:h-[32.5rem] mx-auto">
                    <div className="fade-card mx-auto relative md:top-[3.25rem] top-[2.125rem]"></div>
    
                    <div className="inner-card mx-auto md:w-[100%] md:h-[16.563rem] w-[100%] h-[10.688rem]">
                        <img src="images/it-card-1.png" className="img-card-lock mx-auto md:pt-3" alt="card"/>
                        <img src="images/it-card.png" className="img-card-lock mx-auto" alt="it_card"/>
                        <p className="font-poppins text-[0.875rem] md-text[1rem] font-[400] text-center lg:pt-[2.5rem] pt-[1rem] lg:px-5">
                            MySavvyWallet offers payment options, such as person-to-person payment services, you can pay bills or send money to friends and family. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="containe-lg" className="container min-w-[100%] about-section p-0">
            <div id="down-container" className="w-full mx-0 down-container md:mt-32 mt-6 pb-20 pl-10px">
                <div className="container mx-auto">
                    <p className="title-our-values text-center mx-auto">
                        Our values
                    </p>
    
                    <div id="down-grid"className="grid  mt-6   grid-cols-1 gap-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                        <div className="rounded-lg overflow-hidden card-down  mx-auto sm:max-w-[27rem]">
    
                            <div className="transition-card">
                                <div className="content-section-one sm:relative">
                                    <img src="images/flash.png" className="pt-5 blue-flash pr-2 lg:max-w-full h-auto" alt="flash"/>
                                    <p className="text-white font-roboto text-[1.5rem] font-[600] px-[1.5rem] bottom-[2.813rem] sm:relative max-sm:text-[1.5rem]">Empowerment</p>
                                </div>
                                <div className="content-section-two sm:block">
                                    <p className="font-poppins text-white text-opacity-50 font-[400] text-[0.875rem] mx-auto px-[1.5rem] pt-2  sm:relative bottom-[2.85rem] max-sm:text-[1rem] max-sm:pt-[1.5rem]">We believe in empowering individuals with the tools and knowledge they need to take control of their finances, helping them achieve their financial goals and dreams.</p>
                                </div>
                            </div>
                        </div>
    
                        <div id="overflow-hidden" className="rounded-lg overflow-hidden  card-down  mx-auto sm:max-w-[27rem] ">
    
                            <div className="transition-card">
                                <div className="content-section-one sm:relative  ">
                                    <img src="images/lock-1.png" className="pt-5 blue-flash pr-2 lg:max-w-full h-auto" alt="lock"/>
                                    <p className="text-white font-roboto text-[1.5rem] font-[600] px-[1.5rem] bottom-[2.813rem] sm:relative max-sm:text-[1.5rem]">Security</p>
                                </div>
                                <div className="content-section-two sm:block ">
                                    <p className="font-poppins text-white text-opacity-50 font-[400] text-[0.875rem] mx-auto px-[1.5rem] pt-2  sm:relative bottom-[2.85rem] max-sm:text-[1rem] max-sm:pt-[1.5rem]">We prioritize the security of our users' personal and financial information by using encryption and 2-factor authorization, giving our users peace of mind when making transactions.</p>
                                </div>
                            </div>
                        </div>
    
                        <div id="rounded-lg"className="rounded-lg overflow-hidden card-down down-card mx-auto sm:max-w-[27rem] ">
    
                            <div id="section-one" className="transition-card">
                                <div id="" className="content-section-one sm:relative ">
                                    <img src="images/code.png" className="pt-5 blue-flash pr-2 xl:max-w-[40.9%] h-auto lg:max-w-[51%]" alt="code"/>
                                    <p className="text-white font-roboto text-[1.5rem] font-[600] px-[1.5rem] bottom-[2.813rem] sm:relative max-sm:text-[1.5rem]">Innovation</p>
                                </div>
                                <div className="content-section-two sm:block ">
                                    <p className="font-poppins text-white text-opacity-50 font-[400] text-[0.875rem] mx-auto px-[1.5rem] pt-2  sm:relative bottom-[2.85rem] max-sm:text-[1rem] max-sm:pt-[1.5rem]">We strive to continuously innovate and improve our platform, staying up-to-date with the latest technologies and financial trends to provide our users with the best possible experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default About;