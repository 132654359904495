import { Link } from "react-router-dom";
const Cookies = () => {
  return (
    <div class="container m-auto md:grid px-4 lg:min-w-[96%] mb-12 sm:mx-20 min-w-[92%]">
      <div class="tile bg-teal-500 mt-6">
        <h1 class="tile-marker font-roboto text-2xl md:text-6xl font-bold">
          Cookies
        </h1>
      </div>

      <div class="bg-teal-500 font-poppins text-lg font-normal mt-5">
        <p class="m-0 CstmHR">Last updated: August 23, 2023</p>
       
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>
          This Cookies Policy explains what Cookies are and how We use them. You
          should read this policy so You can understand what type of cookies We
          use, or the information We collect using Cookies and how that
          information is used. This Cookies.
        </p>
        <p className="mt-2">
          Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about You
          may be linked to the information stored in and obtained from Cookies.
          For further information on how We use, store and keep your personal
          data secure, see our Privacy Policy.
        </p>
        <p className="mt-2">
          We do not store sensitive personal information, such as mailing
          addresses, account passwords, etc. in the Cookies We use.
        </p>
      </div>

      <div className="bg-teal-500 font-roboto_slab mt-3">
        <h1 className="text-2xl font-bold">
          Interpretation and Definitions
        </h1>
        <h2 className="text-xl font-bold">Interpretation</h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
      </div>

      <div className="bg-teal-500 font-roboto text-[20px]">
        <h2 className="text-[24px] font-bold mt-3 ">Definitions</h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>For the purposes of this Cookies Policy:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Cookies Policy) refers to White Wolf Enterprises, LLC, 11425 S.
            Black Forest Dr., Sandy, UT. 84094.
          </li>
          <li>
            <strong>Cookies</strong> means small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            details of your browsing history on that website among its many
            uses.
          </li>
          <li>
            <strong>Website</strong> refers to My Savvy Wallet, accessible from{" "}
            <Link
              className="break-all"
              to="https://www.mysavvywallet.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://www.mysavvywallet.com
            </Link>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Website, or a company, or any legal entity on behalf of which such
            individual is accessing or using the Website, as applicable.
          </li>
        </ul>
      </div>

      {/* <div className="bg-teal-500 font-roboto font-semibold mt-6">
        <h1 className="text-2xl">The use of the Cookies</h1>
        <h2 className="text-xl">Type of Cookies We Use</h2>
      </div> */}

      <div className="bg-teal-500 font-roboto_slab  mt-6">
        <h1 className=" text-[27px] font-bold">The use of the Cookies</h1>
        <h2 className=" text-[23px] font-bold">Type of Cookies We Use</h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close your web browser.
        </p>
        <p className="mt-2">
          We use both session and persistent Cookies for the purposes set out
          below:
        </p>
        <ul className="list-disc ml-6 mt-2">
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
      </div>

      {/* <div className="bg-teal-500 font-roboto font-semibold mt-6">
        <h2 className="text-xl">Your Choices Regarding Cookies</h2>
      </div> */}
      <div className="bg-teal-500  font-semibold mt-0">
        <h2 className="text-[26px] font-bold font-roboto mt-3">
          Your Choices Regarding Cookies
        </h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>
          If You prefer to avoid the use of Cookies on the Website, first You
          must disable the use of Cookies in your browser and then delete the
          Cookies saved in your browser associated with this website. You may
          use this option for preventing the use of Cookies at any time.
        </p>
        <p className="mt-2">
          If You do not accept Our Cookies, You may experience some
          inconvenience in your use of the Website and some features may not
          function properly.
        </p>
        <p className="mt-2">
          If You'd like to delete Cookies or instruct your web browser to delete
          or refuse Cookies, please visit the help pages of your web browser.
        </p>
        <ul className="list-disc ml-6 mt-2">
          <li>
            <p>
              For the Chrome web browser, please visit this page from Google:
              <Link
                className="break-words"
                to="https://support.google.com/accounts/answer/32050"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://support.google.com/accounts/answer/32050
              </Link>
            </p>
          </li>
          <li>
            <p>
              For the Internet Explorer web browser, please visit this page from
              Microsoft:
              <Link
                className="break-words"
                to="http://support.microsoft.com/kb/278835"
                rel="external nofollow noopener"
                target="_blank"
              >
                http://support.microsoft.com/kb/278835
              </Link>
            </p>
          </li>
          <li>
            <p>
              For the Firefox web browser, please visit this page from Mozilla:
              <Link
                className="break-words"
                to="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
              </Link>
            </p>
          </li>
          <li>
            <p>
              For the Safari web browser, please visit this page from Apple:
              <Link
                className="break-words"
                to="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </Link>
            </p>
          </li>
        </ul>
        <p>
          For any other web browser, please visit your web browser's official
          web pages.
        </p>
      </div>

      {/* <div className="bg-teal-500 font-roboto font-semibold mt-6">
        <h2 className="text-xl">More Information about Cookies</h2>
      </div> */}
      <div className="bg-teal-500  font-semibold mt-0">
        <h2 className="text-[25px] mt-3 font-bold font-roboto">
          More Information about Cookies
        </h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-3">
        <p>
          You can learn more about cookies:{" "}
          <Link
            className="break-words"
            to="https://www.privacypolicies.com/blog/cookies/"
            target="_blank"
          >
            What Are Cookies?
          </Link>
          .
        </p>
      </div>

      {/* <div className="bg-teal-500 font-roboto font-semibold mt-6">
        <h2 className="text-xl">Contact Us</h2>
      </div> */}

      <div className="bg-teal-500 font-roboto font-semibold mt-6">
        <h2 className="text-[25px] mt-9 font-bold font-roboto mb-4">
          Contact Us
        </h2>
      </div>

      <div className="bg-teal-500 font-poppins text-lg font-normal mt-2">
        <p>
          If you have any questions about this Cookies Policy, You can contact
          us:
        </p>
        <ul className="list-none ml-6 mt-2 mb-5">
          <li>
            <p>By email: info@mysavvywallet.com</p>
          </li>
          <li>
            <p>
              By visiting this page on our website:{" "}
              <Link
                className="break-words"
                to="/pages/cookies"
                rel="external nofollow noopener"
              >
                https://www.mysavvywallet.com/cookie-policy
              </Link>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Cookies;
