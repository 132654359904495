import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link here
import Sidebar from "../../Components/Sidebar";
import UserWelcome from "../../Components/Auth/Registration/UserWelcome";
import LoginWelcome from "../../Components/Auth/Login/LoginWelcome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HeaderComponent = () => {
  const [isModalOpenLogin, setModalOpenLogin] = useState(false);
  const [isModalOpenGet, setModalOpenGet] = useState(false);

  const comingSoon = (tab) => {
    toast(tab + " coming soon!");
  };
  return (
    <>
      <header>
        <div id="container" className="mx-auto max-w-[100%] min-w-[96%]">
          <nav className="w-full">
            <div
              id="items"
              className="flex items-center justify-between p-4 lg:py-5 lg:px-12 w-full flexMin"
            >
              <div
                id="itemCenter"
                className="mr-6 flex flex-shrink-0 items-center"
              >
                <Link to="/">
                  <img
                    className="w-[8.375rem] md:w-[12rem]"
                    src="/images/logo/logo.svg"
                    alt="My Savvy Wallet Logo"
                  />
                </Link>
              </div>

              <div
                id="hidden"
                className="hidden flex-1 items-center justify-center lg:ml-[9.688rem] md:block mr-4 lg:mr-6"
              >
                <div className="flex space-x-14">
                  <Link
                    to="/about"
                    className="rounded-md text-lg text-black font-poppins font-[500] hover:text-main-blue no-underline"
                  >
                    About
                  </Link>
                  <Link
                    to="/news"
                    className="rounded-md text-lg text-black font-poppins font-[500] hover:text-main-blue no-underline"
                    rel="noopener noreferrer"
                  >
                    News
                  </Link>
                </div>
              </div>

              <div
                id="hidden"
                className="hidden md:flex flex md:flex-wrap md:justify-end md:w-1/2 lg:w-1/2 pt-2 gap-2"
              >
                <div className="flex flex-col lg:w-auto md:w-2/5  ml-2 mr-2">
                  <div>
                    <ToastContainer />
                    <button
                      type="button"
                        // onClick={() => setModalOpenGet(true)}
                          onClick={() => comingSoon("Get started")}
                      // onClick={showToastMessage}
                      className="flex rounded px-3 outline text-main-blue py-2.5 md:px-6  mr-6 md:px-6 md:py-4 w-[139px] h-[53px] px-[24px] py-[16px]"
                      aria-expanded="false"
                    >
                      <span
                        className="font-poppins font-[500] text-md md:text-[0.813rem] w-full"
                        id="btn-register"
                      >
                        Get Started
                      </span>
                    </button>
                  </div>
                </div>
                {/* <div className="flex flex-col w-1/4 ml-4">
                <div className="w-1/4"> */}
                <button
                  //  onClick={() => setModalOpenLogin(true)}
                  onClick={() => comingSoon("Login")}
                  type="button"
                  className="flex rounded px-3 outline text-main-blue py-2.5 md:px-6 md:px-6 md:py-4 w-[139px] h-[53px] px-[24px] py-[16px]"
                  aria-expanded="false"
                >
                  <span
                    className="font-poppins font-[500] text-md md:text-[0.813rem] w-full"
                    id="btn-login"
                  >
                    Login
                  </span>
                </button>

                {/* </div>
              </div> */}
              </div>

              <Sidebar />
              <LoginWelcome
                isModalOpenLogin={isModalOpenLogin}
                setModalOpenLogin={setModalOpenLogin}
              />
              <UserWelcome
                isModalOpenGet={isModalOpenGet}
                setModalOpenGet={setModalOpenGet}
              />
            </div>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto"></ul>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    Register
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default HeaderComponent;
