import React, { useState, useEffect } from "react";
import Modal from "../../CommonModal/Modal";
import UserRegistration from "./UserRegistration";

const ConfirmEmail = ({ isOpenConfirm, setOpenConfirm,isOpenRegistration,setOpenRegistration }) => {
  const [verificationCode, setVerificationCode] = useState(Array(6).fill("0"));
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [timeRemainingf, setTimeRemainingf] = useState(false);
//   const [isOpenRegistration, setOpenRegistration] = useState("");
  // const [resendCode, setResendCode] = useState(true); // Control the resend code link

  const handleVerificationChange = (index, value) => {
    if (value.length <= 1) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      if (value && index < 5) {
        document.getElementById(`verification-input-${index + 1}`).focus();
      }
    }
  };

  const handleBack = () => {
    setOpenConfirm(false);
    setOpenRegistration(true);
    setVerificationCode(Array(6).fill("0"));
  };
  
  const refendcode = () => {
    setTimeRemainingf(true)
  };

  useEffect(() => {
    let timer;
    if (isOpenConfirm && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeRemainingf]);

  return (
    <>
      {isOpenConfirm && (
        <Modal onClose={() => setOpenConfirm(false)}>
          {ConfirmEmail ? (
            <div>
              <h4 className="font-poppins font-bold mb-3">
                {" "}
                Confirm your E-mail{" "}
              </h4>

              {/* <p>Enter the Code that was sent to your E-mail <b>{formattedEmail}</b></p> */}

              <div className="flex space-x-2 mt-4">
                {verificationCode.map((digit, index) => (
                  <React.Fragment key={index}>
                    <input
                      id={`verification-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) =>
                        handleVerificationChange(index, e.target.value)
                      }
                      className="border border-gray-300 rounded-md py-2 px-4 w-12 text-center"
                    />
                    {index === 2 && (
                      <span className="text-gray-800 justify-center text-center mt-2">
                        -
                      </span>
                    )}{" "}
                    {/* Add the dash after the second input */}
                  </React.Fragment>
                ))}
              </div>
              <p className="mt-4 text-[10px] font-normal font-poppins"
                onClick={refendcode}
              >
                Resend Code After{" "}
                <b>
                  {Math.floor(timeRemaining / 60)}:
                  {(timeRemaining % 60).toString().padStart(2, "0")}
                </b>
              </p>
              <div className="flex gap-5">
                <button
                  onClick={handleBack}
                  className="h-15 font-[500] py-3 px-4 rounded-lg mt-8 w-40 bg-white text-main-blue"
                  style={{ border: "1px solid blue" }}
                >
                  Back
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-[200] py-3 px-4 rounded-lg mt-8 w-40"
                  style={{ background: "rgba(55, 96, 224, 1)" }}
                >
                  Continue
                </button>
              </div>
            </div>
          ) : null}
        </Modal>
      )}
      {/* {isOpenRegistration && (
        <UserRegistration
          isOpenRegistration={isOpenRegistration}
          setOpenRegistration={setOpenRegistration}
        />
      )} */}
    </>
  );
};
export default ConfirmEmail;
