import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link here
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import UserWelcome from "./Auth/Registration/UserWelcome";

const Sidebar = () => {

  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isModalOpenGet, setModalOpenGet] = useState(false);

  const closeSidebar = () => {
    setIsDivVisible(false);
  };
  const openSidebar = () => {
    setIsDivVisible(true);
  };
  // const comingSoon =(tab)=>{
  //   toast(tab+" coming soon!");
  // };
  return (
    <div>
      {isDivVisible && (
        <div id="shadow" className="fixed inset-0 z-50 flex items-center justify-end shadow-lg">
          <div id="sidebar"
            className={`sidebar w-50 bg-white h-full transform transition-transform duration-300 ease-in-out ${
              isDivVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div id ="center" className="mr-6 flex flex-shrink-0 items-center">
              <Link to="/">
                <img
                  className="w-[8.375rem] md:w-[12rem] p-2"
                  src="/images/logo/logo.svg"
                  alt="My Savvy Wallet Logo"
                />
              </Link>
            </div>
            <div id="close"className="close-sidebar">
              <button onClick={closeSidebar} className="text-sky-blue">
                &times;
              </button>
            </div>
            <div id="text" className="p-4 text-white">
              <Link to="/about" className="block py-2  no-underline cstm-link-sidebar">
                About
              </Link>
    
              <Link
                to="/news"
                className="block py-2  no-underline cstm-link-sidebar"
                // target="_blank"
                rel="noopener noreferrer"
              >
                News
              </Link>
              <Link
                to="#"
                // onClick={() =>comingSoon("Get started")} 
                onClick={() => setModalOpenGet(true)}
                className="block py-2  no-underline cstm-link-sidebar"
                // target="_blank"
                rel="noopener noreferrer"
              >
                Get started
              </Link>
              <Link
                to="#"
                // onClick={() =>comingSoon("Login")} 
                className="block py-2  no-underline cstm-link-sidebar"
                // target="_blank"
                rel="noopener noreferrer"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      )}
      <UserWelcome isModalOpenGet={isModalOpenGet} setModalOpenGet={setModalOpenGet}/>
      {/* <ToastContainer/> */}
      <div id="menu-item" className="block md:hidden menu-btn">
        <button
          className="flex items-center border-0 px-3 py-2"
          aria-controls="mobile-menu"
          aria-expanded="false"
          id="btn-menu"
          onClick={openSidebar}
        >
          <svg
            className="block h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <svg
            className="hidden h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Sidebar;
