// import { useState } from "react";
import { Link } from "react-router-dom"; // Import Link here
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ContactUsModel from "../../Components/Model/ContactUsModal";

const FooterComponent = () => {
  // const [isModalOpenContact, setModalOpenContact] = useState(false);

    const comingSoon =(tab)=>{
        toast(tab+" coming soon!")
      }
      // const  handleClickAbout=()=>{
      //   window.open("/about");
      //   //this opens in a new tab (believe that is what the owner of the question wanted if not you can do window.location.href = "/insert/your/path/here". 
      // }
      // const  handleClickNews=()=>{
      //   window.open("/news");
      //   //this opens in a new tab (believe that is what the owner of the question wanted if not you can do window.location.href = "/insert/your/path/here". 
      // }
  return (
    <footer>
      <div id="containerView" className="container relative mx-auto max-w-[100%]  md:max-w-[100%] lg:min-w-[100%] md:min-w-[100%] h-[696px]">
        <div id="footerImg" className="absolute top-0 left-0 h-full w-full bg-footer-image opacity-70 bg-no-repeat"></div>
        <div id="footerWidth"className="absolute top-0 left-0 h-full w-full bg-gray-dark bg-footer-image" alt="Footer image"></div>

        <div id="viewContainer" className="absolute top-0 left-0 flex h-full w-full">
          <div id="topView" className="absolute top-0 left-0 w-full h-[561px]">
            <div id="topViewCon"className="absolute origin-top-left rounded-full bg-main-blue bg-opacity-[0.98] w-[274.31px] h-[195.50px] left-[25%] top-[20%] rotate-[3.57deg] blur-[150px]"></div>
            <div id="topwidth"className="absolute h-28 origin-top-left rounded-full bg-main-blue bg-opacity-[0.98] w-[157.45px] right-[20%] top-[50%] rotate-[3.57deg] blur-[80px]"></div>
            <div id="gradient" className="absolute top-0 left-0 w-full bg-gradient-to-b to-transparent from-gray-dark h-[212px]"></div>
            <div id="" className="absolute left-0 w-full bg-gradient-to-t to-transparent from-gray-dark h-[240px] bottom-[0px]"></div>
          </div>

          <div id="topView" className="absolute inline-flex w-full flex-col items-center justify-center gap-10 p-0 top-[150px]">
            <div className="relative text-center font-roboto leading-10">
              <span className="font-bold leading-10 text-white text-2xl md:text-6xl">
                Made it to the bottom?
                <br />
                Then it’s time to decide!
                <br />
                Try{" "}
              </span>
              <span className="font-bold leading-10 text-main-blue text-2xl md:text-6xl">
                My Savvy Wallet{" "}
              </span>
              <span className="relative z-10 font-bold leading-10 text-white text-2xl md:text-6xl">
                today!
              </span>
              <img
                className="absolute -right-5 md:right-5 bottom-[-5px] md:-bottom-7 origin-top-left w-[107.369px] md:w-[242.93px] h-[25.735px] md:h-[58.23px] rotate-[1.51deg]"
                src="images/scribble.png" alt="Scribble"/>
            </div>

            {/* <div className="flex items-center justify-center rounded-lg px-6 py-4 bg-main-blue gap-[10px]"><user-registration myvalue="3" button-id="register-footer"></user-registration></div> */}

            <button
              onClick={() =>comingSoon("Start for free")} 
              type="button"
              className="flex items-center justify-center rounded-lg px-6 py-4 bg-main-blue gap-[10px]"
            >
              <div
                className="text-base font-medium leading-tight text-white font-poppins"
                id="btn-start_for_free"
              >
                Start for free
              </div>
            </button>
          </div>

          <div id="bottom" className="absolute bottom-8 w-full inline-flex flex-col gap-6 px-[16px] md:px-[50px]">
            <div id="contantWidth" className="flex flex-col lg:flex-row justify-between">
              <div id="contandHight" className="flex justify-between lg:justify-normal gap-20 mb-6 md:mb-0">
                <div className="inline-flex items-start justify-center gap-4 p-[0px] left-[0px] top-[2px]">
                  <Link to="/">
                    <img
                      className="h-12 w-[201px]"
                      src="images/logo/logo-footer.svg"
                      alt="My Savvy Wallet Logo Footer"
                    />
                  </Link>
                </div>

                <div className="inline-flex items-center justify-start gap-10 p-0">
                  <Link
                  
                    // onClick={() =>comingSoon("")} 
                      to="/about"
                    className="text-base font-medium leading-tight text-white font-poppins no-underline"
                  >
                    About
                  </Link>
                  <Link
                    // onClick={() =>comingSoon("")} 
                      to="/news"
                    className="text-base font-medium leading-tight text-white font-poppins no-underline"
                  >
                    News
                  </Link>
                </div>
              </div>

              <div id="centerText" className="flex justify-between font-md lg:font-base lg:justify-normal gap-2 lg:gap-7 items-center md:ml-2">
                <div className="text-base font-normal leading-relaxed font-poppins text-gray-30 ">
                  Feel free to contact us any time
                </div>

                <div>
                  <div data-v-a94a0c6e="">
                    <button
                      // onClick={() => setModalOpenContact(true)}
                     onClick={() =>comingSoon("Contact us")} 
                      data-v-a94a0c6e=""
                      type="button"
                      class="inline-flex items-center justify-center rounded-lg border border-white px-2 lg:px-6 py-3 lg:py-4 text-white"
                    >
                      Contact us
                    </button>
                  </div>
                </div>
              </div>
            </div>

{/* <ContactUsModel  isModalOpenContact={isModalOpenContact} setModalOpenContact={setModalOpenContact}/> */}
            <div id="footerContainer" className="w-full bg-gray-50 h-[1px]"></div>
            <div className="w-full inline-flex font-poppins items-start justify-between p-0">
              <div id="copyRight"className="font-normal leading-tight text-gray-30 text-[14px]">
                Ⓒ 2023 All rights reserved
              </div>
              <div className="flex items-start justify-start gap-6 p-0">
                <Link
                  className="font-normal leading-tight text-gray-30 text-md flex no-underline" 
                  //  onClick={() =>comingSoon("cookies")}
                  //  target="_blank" 
                   to="/cookies"
                >
                  Cookies
                </Link>
                <Link
                  className="font-normal leading-tight text-gray-30 text-md flex no-underline"
                    // onClick={() =>comingSoon("Privacy Policy")} 
                    //  target="_blank"
                  to="/privacy"
                >
                  Privacy Policy
                </Link>
                <Link
                  className="font-normal leading-tight text-gray-30 text-md flex no-underline md:whitespace-nowrap" 
                   //onClick={() =>comingSoon("Terms and condition")}
                    // target="_blank"
                   to="/terms-and-condition"
                >
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterComponent;
