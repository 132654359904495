import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";

const News = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = currentPage === 1 ? 9 : 10;
  const navigate = useNavigate();
  // const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.marketaux.com/v1/news/all?symbols=TSLA%2CAMZN%2CMSFT&filter_entities=true&language=en&api_token=KiYA5qlT89DOcBZ1vgo9b2ghSWqSOI09ux5VrbZ1"
        );
        setData(response.data.data); // Assuming articles is the correct path
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Calculate pagination
  const startIndex =
    currentPage - 1 === 0
      ? (currentPage - 1) * itemsPerPage
      : (currentPage - 1) * itemsPerPage - 1;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.slice(startIndex, endIndex);
  const length = data?.length % 10;
  const totalPages =
    length === 0
      ? Math.ceil(data?.length / 10) + 1
      : Math.ceil(data?.length / 10);

      // const handelNav = (id) =>{
        // console.log("slsdfdsfdsfds",id)
        // navigate(`/users:${id}`)   
        ///users:userid
        // navigate({
        //   pathname: '/users',
        //   search: `?id=${id}`,
        // });
        
      // }
  return (
    <div className="container block mb-6 md:mb-12 px-4 lg:px-12 min-w-[95%]">
      <div className="flex justify-center items-center pb-6 md:py-8">
        <div className="text-center">
          <h1 className="md:text-6xl sm:text-3xl font-roboto font-bold">
            MSW News
          </h1>
          <span className="block mt-4 text-lg font-poppins text-gray-50">
            Follow the MSW news for product announcement, feature updates, user
            stories, and technical posts about wallet
          </span>
        </div>
      </div>

      <div>
        <ul className="p-0">
          {currentPage === 1 ? (
            <div className="flex flex-col mb-6 md:mb-8" 
           // onClick={() => handelNav(currentPage)}
            >
              {/* First item in a single div */}
              <div className="flex flex-col lg:flex-row bg-light-2 rounded-2xl mb-10 items-center">
                <div className="lg:w-1/2 lg:pr-2.5">
                  <img
                    src={currentItems[0]?.image_url}
                    alt="bannerimg"
                    className="rounded-2xl object-cover w-full h-full lg:max-h-[450px] max-h-[350px] lg:min-h-[450px]"
                  />
                </div>
                <div className="lg:w-1/2 flex flex-col justify-start p-4 lg:p-10">
                  <p className="text-md text-gray-34 font-poppins mb-4 lg:mb-8">
                    {currentItems[0]?.published_at}
                  </p>
                  <h3 className="font-bold text-2xl font-roboto mb-4">
                    {currentItems[0].title}
                  </h3>
                  <span className="text-gray-50 text-base">
                    {currentItems[0]?.description}
                  </span>
                </div>
              </div>
              {/* Remaining two items in a grid layout */}
              <div className="flex flex-wrap">
                {currentItems.slice(1, 9).map((item) => (
                  <div className="lg:w-1/2 mb-10 lg:pr-2.5" key={item.uuid}  
                  // onClick={() => handelNav(item.uuid)}
                  >
                    <img
                      src={item?.image_url}
                      alt="Imagen"
                      height="320"
                      className="rounded-2xl mb-4 object-cover w-full lg:max-h-[450px] max-h-[350px] lg:min-h-[450px]"
                    />
                    <div className="flex flex-col justify-start">
                      <p className="text-md text-gray-34 font-poppins mb-2">
                        {item?.published_at}
                      </p>
                      <h3 className="text-2xl font-bold font-roboto mb-2">
                        {item?.title}
                      </h3>
                      <span className="text-gray-50 text-base">
                        {item?.description}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap" >
              {currentItems.map((item) => (
             
                  <div className="lg:w-1/2 mb-10 lg:pr-2.5" key={item.uuid}  
                  // onClick={() => handelNav(item.uuid)}
                  >
                    {/* <h1 style={{backgroundColor:"red"}}>{item.uuid}</h1> */}
                  <img
                    src={item?.image_url}
                    alt="Imagen"
                    height="320"
                    className="rounded-2xl mb-4 object-cover w-full lg:max-h-[450px] max-h-[350px] lg:min-h-[450px]"
                  />
                  <div className="flex flex-col justify-start">
                    <p className="text-md text-gray-34 font-poppins mb-2">
                      {item?.published_at}
                    </p>
                    <h3 className="text-2xl font-bold font-roboto mb-2">
                      {item.title}
                    </h3>
                    <span className="text-gray-50 text-base">
                      {item?.description}
                    </span>
                  </div>
                </div>
               
                // <Navigate to="/users" replace={true} >
                //    <div className="lg:w-1/2 mb-10 lg:pr-2.5" key={item.id}>
                //   <img
                //     src={item?.image_url}
                //     alt="Imagen"
                //     height="320"
                //     className="rounded-2xl mb-4 object-cover w-full lg:max-h-[450px] max-h-[350px] lg:min-h-[450px]"
                //   />
                //   <div className="flex flex-col justify-start">
                //     <p className="text-md text-gray-34 font-poppins mb-2">
                //       {item?.published_at}
                //     </p>
                //     <h3 className="text-2xl font-bold font-roboto mb-2">
                //       {item.title}
                //     </h3>
                //     <span className="text-gray-50 text-base">
                //       {item?.description}
                //     </span>
                //   </div>
                // </div>
                // </Navigate>
              ))}
            </div>
          )}
        </ul>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-center cursor-pointer font-normal">
            <li className="page-item">
              <Link
                className="page-link text-black"
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link text-black">
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </Link>
            </li>
            <li className="page-item">
              <Link
                className="page-link text-black"
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
              >
                Next
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default News;
