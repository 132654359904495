import { useState } from "react";
import Modal from "../../CommonModal/Modal";
import { Link } from "react-router-dom";
import ConfirmEmail from "./ConfirmEmail";
const UserRegistration=({ isOpenRegistration,setOpenRegistration})=> {
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const handleSubmit = (event) => {
    console.log("sdfhkdskfds")
    event.preventDefault();
    setOpenRegistration(false)
    setOpenConfirm(true)
  };
  //console.log("sdfhkdskfds")

  return (
    <>
      {isOpenRegistration && (
        <Modal onClose={() => setOpenRegistration(false)}>
          <form onSubmit={handleSubmit}>
            <h2
              data-v-9ac1a4e2=""
              id="email-title"
              className="md:text-6xl text-4xl font-[800] md:font-semibold font-roboto"
            >
              {" "}
             Registration
            </h2>
            <p
             
              class="font-[400] text-[1rem] font-poppins"
            >
              Complete the information below and we’ll get back to you soon
            </p>
         
            <label htmlFor="name" className="font-[600] text-[14px] font-poppins">Name </label>
            <input
              id="name"
              type="text"
              placeholder="Name"
              className="border border-gray-300 rounded-md py-2 px-4 w-full bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
              style={{ bordercolor: "rgba(230, 230, 230, 1)" }}
              v-model="name"
              required
            />
            <label htmlFor="email" className="font-[600] text-[14px] font-poppins">Email </label>
            <input
              id="email_address"
              type="email"
              placeholder="Email"
              className="border border-gray-300 rounded-md py-2 px-4 w-full bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              style={{ bordercolor: "rgba(230, 230, 230, 1)" }}
              v-model="email_address"
              required
            />
            {/* <p class="font-[400] text-[14px] font-poppins mt-2">Message</p> */}
            <label htmlFor="Message" className="font-[600] text-[14px] font-poppins">Message</label>
            <textarea
              id="message"
              type="text"
              placeholder="Write your message"
              class="border border-gray-300 rounded-md py-2 px-4 w-full bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
              style={{ bordercolor: "rgba(230, 230, 230, 1)" }}
              v-model="message"
              required
            ></textarea>
            <button id="email-submit" type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-[400] py-3 px-4 rounded-lg mt-8 w-full" data-v-9ac1a4e2=""
            //onClick={() => {setOpenConfirm(true);setOpenRegistration(false) }}
            > Submit </button>
            <div
              id="textB"
              className="bg-blue-500 hover:bg-blue-600 font-normal py-3 px-4 rounded-lg mt-8 w-full text-black mb-10 text-center"
              data-v-9ac1a4e2=""
            >
              {" "}
              Invisible Recaptcha by Google{" "}
              <span className="font-bold" data-v-9ac1a4e2="">
                <Link to="/privacy" data-v-9ac1a4e2="">
                  Privacy Policy
                </Link>
              </span>{" "}
              and{" "}
              <span class="font-bold" data-v-9ac1a4e2="">
                <Link to="/terms-and-conditions" data-v-9ac1a4e2="">
                  Terms of Use
                </Link>
              </span>
            </div>
          </form>
        </Modal>
      )}
      <ConfirmEmail isOpenConfirm={isOpenConfirm} setOpenConfirm={setOpenConfirm} isOpenRegistration={isOpenRegistration}setOpenRegistration={setOpenRegistration}/>
    </>
  );
}

export default UserRegistration;
