import Modal from "../../CommonModal/Modal";
import { Link } from "react-router-dom";

const LoginWelcome = ({isModalOpenLogin,setModalOpenLogin}) =>{
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {isModalOpenLogin && (
        <Modal onClose={() => setModalOpenLogin(false)}>
          <form onSubmit={handleSubmit}>
          <h2 data-v-9ac1a4e2="" id="email-title" className="md:text-4xl text-4xl font-[800] md:font-semibold font-roboto"> Welcome! </h2>
          <label htmlFor="email" className="font-[400] text-[14px] font-poppins">Enter Email*</label>
          <input  
            type="email" 
            id="email" 
            required
            placeholder="Enter Email"
            className="border border-gray-300 rounded-md py-2 px-2 w-full bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
          /><br/>
          <button id="email-submit" type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-[400] py-3 px-4 rounded-lg mt-8 w-full" data-v-9ac1a4e2=""> Continue </button>
          <div id="textB" className="bg-blue-500 hover:bg-blue-600 font-normal py-3 px-4 rounded-lg mt-8 w-full text-black mb-10 text-center" data-v-9ac1a4e2=""> Invisible Recaptcha by Google <span className="font-bold" data-v-9ac1a4e2="">
            <Link to="#" className="no-underline" data-v-9ac1a4e2="">Privacy Policy</Link></span> and <span class="font-bold" data-v-9ac1a4e2="">
            <Link to="#" className="no-underline" data-v-9ac1a4e2="">Terms of Use</Link></span></div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default LoginWelcome;


