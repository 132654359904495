import { Link } from "react-router-dom";
const Newsdetail = () => {
  return (
    <div className="container block mb-24 px-4 lg:px-32">
      <div className="flex justify-center items-center py-16">
        <div className="text-center">
          <h1 className="text-6xl font-roboto font-bold">MSW News</h1>
          <span className="block mt-4 text-lg font-poppins text-gray-50">
          US Housing Affordability Hits Worst Point in Nearly Four Decades
          </span>
        </div>
      </div>
      </div>
   
  );
};
export default Newsdetail;




















