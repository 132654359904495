import '../../assets/css/slick.min.css'
import '../../assets/css/landing.css'
import '../../App.css'
import TestimonialCarousel from "../../Components/Testimonials";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = () => {
    const showToastMessage = () => {
        toast("coming soon!")
          // position: toast.POSITION.TOP_RIGHT,
      }
    return (
        <div id="container">
        <div id="content" className="w-full flex flex-col lg:flex-row content1">
            <div id ="view" className="left-pard p-6 md:ml-12 w-full sm:w-2/5 ">
                <div id="title-flex"className="title flex-row">
                    <b className="the-comprehensive-way" >The comprehensive way to look at your personal finances
                    </b >
                    <div id ="we-help" className="we-help-you">
                        We help you place all your financial information in one place so
                        you can have a comprehensive view of all the factors that
                        influence it.
                    </div>
                </div>

                {/* <div className="buttons1">
                    <div data-v-9ac1a4e2=""> */}
                        <button   onClick={showToastMessage} data-v-9ac1a4e2="" id="register-home" type="button" className="buttons1">Get started for free</button>
                    {/* </div> */}
                {/* </div> */}
                {/* <div className="buttons1"><user-registration :myvalue="2" button-id="register-home"></user-registration></div> */}

            </div>
            <div id="directions"className="direction w-full sm:w-[10%] flex items-start">
                <div id="content-child"className="content-child flex items-start sm:mt-32">
                    <img id="full-view" className="w-full" alt="group" src="images/group-69@2x.png" />
                </div>
                <div id="full content-child-mobile" className="w-full content-child-mobile">
                    <img alt="mobile" src="images/group-69-mobile@2x.png" />

                </div>
            </div>

            <div id="right-part"className="right-part w-full sm:w-1/2  mt-10">
                <div className="flex w-full right-part-img"><img src="images/right-part2.png" alt="right_part"/></div>
            </div>
        </div>

        <div id="w-full flex flex-col" className="w-full flex flex-col m-web-finance md:flex-row md:mb-20">
            <div id="content-title" className="m-title  font-bold text-left text-5xl leading-[1.2] p-6 w-1/2 pl-16"> The only web-site for your personal finances </div>
            <div className="m-finance-desc w-1/2 p-6">
                MySavvyWallet is a comprehensive financial management service that
                offers money transfer and bill payment options, credit card debt
                reminders, automated expense categorization and budgeting, as well as
                account synchronization for easier management, and much more. It also
                provides investment recommendations to help you achieve long-term
                financial goals.
            </div>
        </div>

        <div id="dashboard" className="dashboard ml-[8%] md:mt-10">
            <div className="m-cards">
                <img className="dashboard-img" src="images/dashboard.png" alt="dashboard_img"/>
            </div>
            <div className="d-cards">
                <img src="images/dashboard.png" alt="dash_board_img"/>
            </div>

        </div>
        <div id="home-final-page-item "className="home-final-page-item flex flex-col justify-center">
            <div className="offerings">
                <b className="explore-our-offerings">Explore Our Offerings</b>
                <div className="offerings1 flex flex-wrap">
                    <div className="offer w-full sm:w-1/2 md:w-1/3  p-4 flex flex-col">
                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon1"
                                alt="rectangle_icon"
                                src="images/rectangle@2x.png"
                            />
                        </div>
                        <div className="text1">
                            <div className="credit-card-debts">Credit card debts reminder</div>
                            <div id ="reminders" className="reminders-about-the">
                                Reminders about the need to pay credit card debts, as well as
                                reminders about the end of the grace period.
                            </div>
                        </div>
                    </div>

                    <div id ="width-full" className="offer2 w-full sm:w-1/2 md:w-1/3  p-4">
                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon3"
                                alt="rectangle_icon3"
                                src="images/rectangle2@2x.png"
                            />
                        </div>
                        <div id="text" className="text1">
                            <div className="credit-card-debts">Money Transfer</div>
                            <div id ="about_title" className="reminders-about-the">
                                This service allows you to transfer funds from one account to
                                another and pay bills. It is available with a lot of banks, even
                                small ones around the world.
                            </div>
                        </div>
                    </div>

                    <div id="transfer-w-0" className="offer4 w-full sm:w-1/2 md:w-1/3 p-4">
                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon5"
                                alt="rectangle_icon5"
                                src="images/rectangle4@2x.png"
                            />
                        </div>
                        <div id="bill-payment"className="text1">
                            <div className="bill-payment ">Bill payment</div>
                            <div className="reminders-about-the">
                                You can pay your bills which is a convenient option for busy
                                people who want to save time and avoid late fees.
                            </div>
                        </div>
                    </div>
                    <div id="" className="offer5 w-full sm:w-1/2 md:w-1/3  p-4">
                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon6"
                                alt="rectangle-icon6"
                                src="images/rectangle5@2x.png"
                            />
                        </div>
                        <div id="invoices" className="text1">
                            <div className="creating-invoices-or-container">
                                <p className="my-savvy m-0">Creating invoices</p>
                                <p className="my-savvy">or budgets</p>
                            </div>
                            <div id="about-the" className="reminders-about-the">
                                Сreate large purchases or savings - savings each month
                                automatically "disappear" so you don’t count on them.
                            </div>
                        </div>
                    </div>

                    <div id="offer" className="offer3 w-full sm:w-1/2 md:w-1/3 p-4">
                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon4"
                                alt="rectangle-icon4"
                                src="images/rectangle3@2x.png"
                            />
                        </div>
                        <div className="text1">
                            <div className="credit-card-debts">
                                <p className="my-savvy m-0">Synchronization</p>
                                <p className="my-savvy">of accounts</p>
                            </div>
                            <div className="reminders-about-the">
                                For comfortable management of your budget, you can synchronize
                                all financial accounts.
                            </div>
                        </div>
                    </div>
                    <div className="offer w-full sm:w-1/2 md:w-1/3 p-4">

                        <div className="transfer-3d">
                            <img
                                className="rectangle-icon2"
                                alt="rectangle-icon2"
                                src="images/rectangle1@2x.png"
                            />
                        </div>
                        <div className="text1">
                            <div className="credit-card-debts">
                                <span className="my-savvy">Recommendations on investing</span>
                            </div>
                            <div className="reminders-about-the">
                                MySavvyWallet can help you make informed decisions about how to
                                invest your money wisely for long-term financial goals.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="solutions" className="solutions">
            <div className="text7">                
                <b className="explore-our-offerings mb-solutions">Our solutions</b>
                <div className="from-the-ability">
                    From the ability to manage multiple bank accounts to reducing
                    financial stress
                </div>
            </div>
            <div id="div" className="div102">
                <div className="bg3 w-full flex flex-wrap">
                    <div className="text-with-btn2 p-4 w-full ms:w-1/2 flex flex-col">
                        <div className="btns">
                            <b className="explore-our-offerings mb-solutions">All your accounts, all in one place
                            </b >
                            <div className="separate-your-personal">
                                Separate your personal and professional life for an easier
                                transition between different roles and responsibilities.
                                Consolidate all your financial accounts into one place in a
                                hassle-free way.
                            </div>
                            <div className="button-offering flex">
                                <div className="text-btn">Discover open wallet</div>
                                <div className="btn-arrow">
                                    <img src="images/arrow.png" alt="arrow"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="account-cards" className="account-cards w-full ms:w-1/2 flex flex-col">
                        <div className="d-cards flex flex-col w-full h-[305px] items-center">

                            <img className="cards-pc" src="images/cards@pc.png" alt="cards"/>
                        </div>
                        <div className="m-cards flex-col items-center">
                            <img className="m-cards-img" src="images/cr-cards.png" alt="cards_img"/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fullFlex" className="w-full flex flex-wrap mt-5">
                <div className="w-full sm:w-1/2">
                    <div className="div95 w-full flex flex-row">
                        <div className="bg flex flex-wrap items-center">
                            <div className="text-with-btn flex flex-col p-4 w-full sm:w-1/2">
                                <div className="btns">
                                    <b className="explore-our-offerings mb-solutions">Bank accounts</b>
                                    <div className="multiple-bank-accounts">
                                        Multiple bank accounts can help you keep your personal and
                                        professional life separate, allowing you to switch roles and
                                        responsibilities easily.
                                    </div>
                                    <div className="button-offering flex">
                                        <div className="text-btn">Discover open wallet</div>
                                        <div className="btn-arrow">
                                            <img src="images/arrow.png" alt="arr"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="bank-cards" className="bank-cards flex-col w-full sm:w-1/2 ">
                                <div className="d-cards">

                                    <img className="bank-acount-cards" src="images/bank-account-cards.png" alt="bank_account"/>
                                </div>
                                <div className="m-cards">
                                    <img className="" src="images/mul-cards.png" alt="mul-cards"/>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div id="full_flex" className="div99 w-full flex flex-row mt-5">
                        <div className="bg">
                            <div className="text-with-btn1 p-4">
                                <div className="text9 btns">
                                    <b className="explore-our-offerings mb-solutions">Payments</b>
                                    <div className="when-transferring-payments">
                                        When transferring payments, using a trusted payment service or
                                        platform, such as MySavvyWallet, will minimize the risk of fraud
                                        or unauthorized access to your funds.
                                    </div>
                                </div>
                            </div>
                            <div id="d_cards" className="d-cards">
                                <div className="line3">
                                    <img src="images/payments-graph.png" alt="payments_graph"/>
                                </div>
                            </div>
                            <div className="m-cards">
                                <img src="images/lines.png" alt="lines_graph"/>
                            </div>

                        </div>

                    </div>
                </div>
                <div id="" className="w-full sm:w-1/2">
                    <div className="div101">
                        <div className="bg2 flex flex-col items-center">
                            <div className="content3 p-4 pt-8 flex flex-col items-center justify-center">
                                <div className="text10">
                                    <b className="explore-our-offerings mb-solutions">Control your budget</b>
                                    <div className="knowing-where-your">
                                        Knowing where your money is going and how much you have to spend
                                        can help reduce financial stress and anxiety. Controlling your
                                        budget can help you to avoid overspending and ensure you have
                                        enough money to cover your bills and expenses.
                                    </div>
                                    <div className="button-offering flex">
                                        <div className="text-btn">Start control</div>
                                        <div className="btn-arrow">
                                            <img src="images/arrow.png" alt="arrow_img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="" className="iphone-14 flex flex-col items-center justify-end">
                                <div className="d-cards">

                                    <img src="images/iphone-14-desk.png" alt="iphone_14_desk"/>
                                </div>
                                <div className="m-cards">
                                    <img src="images/iphone-14.png" alt="iphone"/>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <TestimonialCarousel />
       {/* <ImageSlider /> */}
    </div>

    );
}

export default HomePage;